import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Standout({ title, content, icon, iconClassName, light = false }) {
  const textColor = light ? "text-white" : "text-cyan-900";
  const bgColor = light ? "bg-cyan-900" : "bg-slate-100";
  return (
    <div className={`flex flex-1 flex-col items-center px-6 py-4 ${bgColor} rounded-2xl ${textColor}`}>
      <FontAwesomeIcon icon={icon} size="4x" className={`py-8 ${iconClassName ?? ''}`} />
      <h4 className="text-2xl font-bold text-center mb-4">
        {title}
      </h4>
      <div className="text-lg text-left">{content}</div>
    </div>
  );
}