import React from "react";
import { Layout } from "../../components/Layout";
import richShawnBaptism from "../../images/rich-shawn-baptism.jpeg";
import { Standout } from "../../components/Standout";
import {
  faBookBible,
  faHandHoldingHeart,
  faCross,
  faDove,
  faPerson,
  faPersonRays,
  faHandshakeAngle,
  faChurch,
} from "@fortawesome/free-solid-svg-icons";

const OurBeliefs = () => {
  return (
    <Layout>
      <main className="relative bg-white z-0 min-h-[80vh]">
        <img
          src={richShawnBaptism}
          alt=""
          className="w-full h-[36rem] md:h-[48rem] object-cover z-0 absolute"
        />
        <div className="w-full h-[36rem] md:h-[48rem] z-10 bg-gray-500 absolute mix-blend-multiply opacity-60" />
        <div className="w-full h-[36rem] md:h-[48rem] z-20 relative">
          <div className="absolute w-full pt-28 px-16 flex flex-col items-start justify-start">
            <h1 className="text-white mt-64 text-5xl md:mt-0 font-serif">
              Our Beliefs
            </h1>
            <h2 className="text-white text-xl md:text-2xl mt-2">
              The Bible is the source of truth and where the good news is
              discovered.
            </h2>
          </div>
        </div>
        <div>
          <div className="text-lg text-cyan-900 text-left mx-12 my-8">
            <div>
              The <span className="font-bold">Gospel</span> is the central
              message of the Bible, in both New and Old Testament.
            </div>
            <div className="max-w-2xl mt-4 flex flex-col">
              <div className="italic font-light">
                For I delivered to you as of first importance what I also
                received: that Christ died for our sins in accordance with the
                Scriptures, that he was buried, that he was raised on the third
                day in accordance with the Scriptures.
              </div>
              <div className="ml-auto">
                <span className="font-bold">1 Corinthians 15:3-4</span>
              </div>
            </div>
          </div>
          <div className="grid grid-flow-row grid-cols-1 gap-8 md:grid-cols-3 md:gap-8 pb-8 px-8">
            <Standout
              title="The Bible"
              content="We believe that the Bible is God's inspired, infallible, and inerrant Word, and is the sole written revelation that rules the faith and practice for all Christians, and alone can bind the conscience. We believe that God has preserved His Word, and that by His power it has been kept from being lost, destroyed, or diluted with error."
              icon={faBookBible}
            />
            <Standout
              title="God"
              content="We believe in one God, Creator of all things, perfect in love and holiness, Who exists as three Persons: Father, Son, and Holy Spirit."
              icon={faHandHoldingHeart}
            />
            <Standout
              title="Jesus Christ"
              content="We believe that Jesus Christ is fully God and entered human form by the miracle of the Virgin Birth, that He lived a sinless life, and that He died for our sins. Christ is the only mediator through whose work we are redeemed. We believe that He rose again on the third day and now sits at the right hand of God as our Advocate."
              icon={faCross}
            />
            <Standout
              title="The Holy Spirit"
              content="We believe that the Holy Spirit is God. His ministry is to glorify the Lord Jesus Christ and convict the world of sin, righteousness, and judgment. We believe that He brings about the new birth, indwells, baptizes, seals, anoints, and endows all who are regenerated with spiritual gifts. The Holy Spirit also leads believers who daily surrender their life to Him by guiding, teaching, sanctifying and filling them."
              icon={faDove}
            />
            <Standout
              title="Humanity and Sin"
              content="We believe that all people are created in the image of God. At the same time, because of Adam's sin, all people are sinful by nature and choice. Apart from forgiveness through Jesus Christ, all are lost and alienated from God."
              icon={faPerson}
            />
            <Standout
              title="Salvation"
              content="We believe that salvation rests solely on the work of God's grace for us and in us. We are justified by grace through faith alone. Christ's death is the only payment for sin that God will accept. Additionally, the righteousness of Christ is transferred to us by faith and is the only merit or righteousness that God will accept. We possess the righteousness of Christ in the sight of God. All who truly believe in Christ are born again and become the children of God. All true believers, once saved, are kept by God's power, not our own, and are thus secure in Christ forever."
              icon={faHandshakeAngle}
              iconClassName="-rotate-[45deg]"
            />
            <Standout
              title="The Resurrection"
              content="We believe in the bodily resurrection of all men, the saved to eternal life, and the lost to judgment and everlasting punishment."
              icon={faPersonRays}
            />
            <Standout
              title="The Church"
              content="We believe that the Church consists of all who believe in Jesus Christ as Savior. The local Church exists to exalt Christ, encourage believers, and evangelize the world. We believe that every local church is a natural expression of the Body of Christ and should seek to encourage all Christhonoring churches and ministries."
              icon={faChurch}
            />
          </div>
        </div>
        <div className="w-full bg-cyan-700 p-8 md:p-16">
          <div className="flex flex-col md:flex-row items-start md:items-center gap-4 justify-between">
            <div className=" text-white text-3xl font-light">
              Meet our elders and deacons
            </div>
            <a
              href="/about/our-leaders"
              className="rounded-full  bg-white hover:bg-gray-200 text-cyan-700 text-sm sm:text-lg px-8 py-3"
            >
              Learn more
            </a>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default OurBeliefs;
